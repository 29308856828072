<template>
  <div>
    <v-expansion-panel-content>
      <p style="color: gray">
        Guide: If you want to create a deal summary lender package, just select
        the files listed below then click the button.
      </p>

      <v-expansion-panels multiple>
        <v-expansion-panel v-for="(category, index) in categories" :key="index">
          <v-expansion-panel-header>
            <h5>
              {{ category.category_name }}
            </h5>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">File Name</th>
                  <th class="text-left">Description</th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>

              <tbody>
                <tr v-for="dealFile in dealFiles" :key="dealFile.id">
                  <td
                    v-if="
                      dealFile.file_cat.category_name ===
                        category.category_name &&
                      dealData.public_name === dealFile.deal.public_name
                    "
                  >
                    <v-checkbox
                      v-model="selectedFiles"
                      :value="dealFile.id"
                      :label="dealFile.file_name"
                    />
                  </td>

                  <td
                    v-if="
                      dealFile.file_cat.category_name ===
                        category.category_name &&
                      dealData.public_name === dealFile.deal.public_name
                    "
                  >
                    {{ dealFile.description }}
                  </td>

                  <td
                    v-if="
                      dealFile.file_cat.category_name ===
                        category.category_name &&
                      dealData.public_name === dealFile.deal.public_name
                    "
                  >
                    <v-icon title="download" @click="downloadFile(dealFile.id)">
                      mdi-download
                    </v-icon>
                  </td>

                  <td
                    v-if="
                      dealFile.file_cat.category_name ===
                        category.category_name &&
                      dealData.public_name === dealFile.deal.public_name
                    "
                  >
                    <v-icon
                      v-show="
                        dealFile.file_type === 'application/pdf' ||
                        dealFile.file_type === 'image/jpeg' ||
                        dealFile.file_type === 'image/png'
                      "
                      title="preview"
                      @click="viewFile(dealFile.file_name, dealFile.file_type)"
                    >
                      mdi-eye
                    </v-icon>
                  </td>

                  <td
                    v-if="
                      dealFile.file_cat.category_name ===
                        category.category_name &&
                      dealData.public_name === dealFile.deal.public_name
                    "
                  >
                    <v-icon
                      title="delete"
                      @click="deleteFile(dealFile.id, dealFile.file_name)"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expansion-panel-content>

    <v-expansion-panel-content>
      <v-btn
        style="cursor: pointer"
        @click="createDealSummaryLenderPackage"
        :disabled="
          selectedFiles.length === 0 || isCreating || isCompleted ? true : false
        "
      >
        <span v-if="!isCreating && !isCompleted">
          Create Deal Summary Lender Package
        </span>

        <span v-if="isCreating && !isCompleted">
          Creating
          <v-progress-circular indeterminate color="primary" />
        </span>

        <span v-if="!isCreating && isCompleted"> Done Creating </span>
      </v-btn>

      <v-alert
        class="mt-5"
        v-show="isCompleted"
        transition="fade-transition"
        type="success"
      >
        Successfully created a Deal Summary Lender Package.
      </v-alert>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import API from '@/plugins/API';

export default {
  name: 'DealFiles',

  props: ['dealData'],

  data: () => ({
    dealFiles: null,
    categories: null,
    selectedFiles: [],
    isCreating: false,
    isCompleted: false,
  }),

  methods: {
    async createDealSummaryLenderPackage() {
      this.isCreating = true;

      this.selectedFiles.forEach((element) => {
        console.log('selected ID: ', element);
      });

      if (this.selectedFiles.length !== 0) {
        API()
          .post(
            `/api/internal-admin/create_deal_summary_lender_package`,
            {
              files: this.selectedFiles,
              dealData: this.dealData,
            },

            { responseType: 'blob' }
          )
          .then((response) => {
            console.log(response);

            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });

            // // this is to preview the result in another tab
            // const url = window.URL.createObjectURL(blob);
            // const win = window.open();
            // win.document.write(
            //   `<embed type="application/pdf" src="${url}#toolbar=0&navpanes=0" frameborder="0" style="top:0; left:0; right:0; bottom:0; width:100%; height:100%;">`
            // );

            this.isCreating = false;
            this.isCompleted = true;

            setTimeout(() => {
              this.getCategoryData();
              this.getDealFile();
              this.isCompleted = false;
            }, 4000);
          })
          .catch((err) => {
            console.log(err);
            this.isCreating = false;
          });
      } else {
        alert('There are no selected files, please select files first.');
        this.isCreating = false;
      }
    },

    downloadFile(id) {
      this.dealFiles.find((el) =>
        el.id === id ? window.open(el.file_link, '_blank') : ''
      );
    },

    async viewFile(fileName, fileType) {
      await API()
        .get(`/api/internal-admin/get_deal_preview_file?params=${fileName}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const file = new Blob([response.data], {
            type: fileType,
          });

          const fileURL = URL.createObjectURL(file);

          console.log(fileType);
          // Open a new tab and set the src of an iframe element to the data URI
          const win = window.open();
          win.document.write(
            `<embed type="application/pdf" src="${fileURL}#toolbar=0&navpanes=0" frameborder="0" style="top:0; left:0; right:0; bottom:0; width:100%; height:100%;">`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async deleteFile(id, fileName) {
      if (window.confirm('Are you sure you want to delete this file?')) {
        await API()
          .post('/api/internal-admin/delete_deal_file', {
            id: id,
            file_name: fileName,
          })
          .then((res) => {
            console.log('the file has been deleted.');
            console.log(res.data);
          })
          .catch((err) => console.log(err));

        this.getDealFile();
      } else {
        console.log('cancel delete');
      }
    },

    async getCategoryData() {
      await API()
        .get('/api/internal-admin/get_upload_file_category')
        .then((res) => {
          this.categories = res.data;
          console.log('categories: ', this.categories)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getDealFile() {
      await API()
        .get('/api/internal-admin/get_deal_file')
        .then((res) => {
          this.dealFiles = res.data;
          console.log('deal files: ', this.dealFiles)
        })
        .catch((err) => {
          console.log(err);
        });

      console.log(this.dealFiles);
    },
  },

  mounted() {
    this.getCategoryData();
    this.getDealFile();
  },
};
</script>
